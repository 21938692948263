import { regex } from '@/utils';
import { messageUI } from '@/utils/text/message';
import * as yup from 'yup';

export const formSchemaFn = () =>
  yup.object().shape({
    name: yup.string().required(messageUI.required),
    postCode: yup
      .string()
      .nullable()
      .test((value) => {
        if (!value) return true;
        return regex.validatePostCode.test(value);
      }),
    email: yup
      .string()
      .matches(regex.validateEmail, 'Invalid company email address')
      .required(messageUI.required),
    phone: yup
      .string()
      .nullable()
      .test((value) => {
        if (!value) return true;
        return regex.validatePhone.test(value);
      }),
    address: yup.string().required(messageUI.required),
    fullNameUser: yup.string().required(messageUI.required),
    genderUser: yup.number().required(messageUI.required),
    emailUser: yup
      .string()
      .matches(regex.validateEmail, 'Invalid email address')
      .required(messageUI.required),
    phoneUser: yup
      .string()
      .nullable()
      .test((value) => {
        if (!value) return true;
        return regex.validatePhone.test(value);
      }),
  } as any);
