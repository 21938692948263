// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-modal-content {
  padding: 0 !important;
}

.ant-modal-header {
  margin-bottom: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Modal/style.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;;AACA;EACE,2BAAA;AAEF","sourcesContent":[".ant-modal-content {\n  padding: 0 !important;\n}\n.ant-modal-header {\n  margin-bottom: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
