import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import AppRoutes from './routes/Routers';
import StatesProvider from './states/index.provider';
import locale from 'antd/es/locale/ja_JP';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#3BB8C6',
        },
      }}
      locale={locale}
    >
      <QueryClientProvider client={queryClient}>
        <StatesProvider>
          <AppRoutes />
        </StatesProvider>
      </QueryClientProvider>
    </ConfigProvider>
  );
}

export default App;
