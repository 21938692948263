import { Empty } from 'antd';

interface IProps {
  description: string;
}
export const DataEmpty: React.FC<IProps> = ({ ...props }) => {
  return (
    <div className='m-auto mt-28'>
      <Empty {...props} />
    </div>
  );
};
