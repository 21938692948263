import { FC, ReactElement } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { LoadingModal } from '@/components/common';
import LayoutComponent from '@/layout';
import Login from '@/pages/Login';
import ChangePassword from '@/pages/Login/ChangePassword';
import ForgotPassword from '@/pages/Login/ForgotPassword';
import ManagerCompany from '@/pages/ManagerCompany';
import DetailCompany from '@/pages/ManagerCompany/Detail';
import FormCompany from '@/pages/ManagerCompany/Form';
import UserInfo from '@/pages/UserInfo';
import ChangePasswordInfo from '@/pages/UserInfo/ChangePassword';
import PrivateRoute from '@/routes/PrivateRoute';
import { useAuth } from '@/states/auth/index.state';
import { useLoading } from '@/states/loading/index.state';
import { getCookie } from '@/utils/storage';
import { Outlet } from 'react-router-dom';
import Unauthorized from '@/pages/Unauthorized';
import ManagerContract from '@/pages/ManagerContract';
import FormContract from '@/pages/ManagerContract/Form';
import { ROLE_KEY } from '@/const/option';
import DetailContract from '@/pages/ManagerContract/Detail';
import NotFound from '@/pages/NotFound';

const AppRoutes: FC = (): ReactElement => {
  const { isAuthenticated, currentUser } = useAuth();
  const { isLoading } = useLoading();
  const role = getCookie('role') ? JSON.parse(getCookie('role')) : 'GUEST';

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <PrivateRoute isAuthenticated={isAuthenticated} userRole={role}>
          <LayoutComponent />
        </PrivateRoute>
      ),
      children: [
        {
          path: '/',
          element: <ManagerContract />,
        },
        {
          element: (
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              userRole={role}
              allowedRoles={['ADMIN']}
            >
              <Outlet />
            </PrivateRoute>
          ),
          children: [
            {
              path: '/company/create',
              element: <FormCompany />,
            },
            {
              path: '/company/detail/:id',
              element: <DetailCompany />,
            },
            {
              path: '/contract/create',
              element: <FormContract />,
            },
            {
              path: '/contract/:id',
              element: <FormContract />,
            },
          ],
        },
        {
          path: '/company',
          element:
            role === ROLE_KEY.ADMIN ? (
              <ManagerCompany />
            ) : (
              <DetailCompany companyId={currentUser?.company?.id} />
            ),
        },
        {
          path: '/company/:id',
          element: <FormCompany />,
        },
        {
          path: '/contract',
          element: <ManagerContract />,
        },
        {
          path: '/user-info',
          element: (
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              userRole={role}
              allowedRoles={['COMPANY']}
            >
              <UserInfo />
            </PrivateRoute>
          ),
        },
        {
          path: '/change-password',
          element: <ChangePasswordInfo />,
        },
        {
          path: '/contract/detail/:id',
          element: <DetailContract />,
        },
      ],
    },
    {
      path: '/login',
      element: (
        <PrivateRoute
          isAuthenticated={!isAuthenticated}
          userRole={role}
          fallback='/contract'
        >
          <Login />
        </PrivateRoute>
      ),
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />,
    },
    {
      path: '/change-password/:code',
      element: <ChangePassword />,
    },
    {
      path: '*',
      element: <NotFound />,
    },
    {
      path: '/unauthorized',
      element: <Unauthorized />,
    },
  ]);

  return (
    <>
      <RouterProvider router={router} fallbackElement={<div>Loading...</div>} />
      <LoadingModal loading={isLoading} />
    </>
  );
};

export default AppRoutes;
