import { Input } from 'antd';
import { PasswordProps } from 'antd/lib/input';
import { ControllerRenderProps } from 'react-hook-form';

interface IProps extends PasswordProps {
  field?: ControllerRenderProps<any, any>;
}

export const InputPassword: React.FC<IProps> = ({
  className,
  field,
  ...props
}) => {
  return <Input.Password className={className} {...field} {...props} />;
};
