export const messageUI = {
  createSuccess: '登録完了しました。',
  updateSuccess: '更新完了しました。',
  update: '更新します。よろしいですか？',
  changeToken: 'このトークンを生成します。よろしいですか？',
  applyToken: 'このトークンを承認します。よろしいですか？',
  subForgotPassword: 'メールアドレスを入力してください。',
  desForgotPassword:
    '* ご登録されているメールアドレスにパスワード再設定のURLをお送りいたします。',
  noData: 'データはありません。',
  emailRequired: 'メールアドレスが入力されていません。',
  emailInvalid: '正しいメールアドレスを入力してください。',
  passwordRequired: 'パスワードが入力されていません。',
  newPasswordRequired: '新しいパスワードが入力されていません。',
  confirmNewPasswordRequired: '新しいパスワード（確認）が入力されていません。',
  passwordMin: 'パスワードは10文字以上で入力してください',
  passwordMax: 'パスワードは100文字以内で入力してください',
  newPasswordMin: '新しいパスワードは10文字以上で入力してください',
  newPasswordMax: '新しいパスワードは100文字以内で入力してください',
  passwordInvalid:
    'パスワードは半角英数字、記号、大文字、小文字を１文字以上で入力してください。',
  passwordDoNotMatch: 'パスワードが一致しません。',
  required: '必須',
  placeholderInput: '入力してください。',
  placeholderSelect: '選択してください。',
};
