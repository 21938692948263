export const APP_ROUTER = {
  HOME: '/',
  USER_INFO: '/user-info',
  NOT_FOUND: '/404',
  AUTH: {
    LOGIN: '/login',
    FORGOT_PASSWORD: '/forgot-password',
    CHANGE_PASSWORD: '/change-password/:code',
    UNAUTHORIZED: '/unauthorized',
  },
  COMPANY: {
    LIST: '/company',
    DETAIL: '/company/detail/:id',
    CREATE: '/company/create',
    UPDATE: '/company/:id',
  },
  CONTRACT: {
    LIST: '/contract',
    CREATE: '/contract/create',
    UPDATE: '/contract/:id',
    DETAIL: '/contract/detail/:id',
  },
  SCORE_INDEX: {
    LIST: '/score-index',
  },
};
