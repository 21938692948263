import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';

const Unauthorized: React.FC = () => {
  return (
    <Result
      status='403'
      title='403'
      subTitle='Sorry, the page you visited can not allow.'
      extra={<Link to={'/'}>
        <Button type='primary'>Back Home</Button>
      </Link>}
    />
  );
};
export default Unauthorized;
