import { DatePicker as DatePickerBase } from 'antd';
import { DatePickerProps } from 'antd/lib';
import { ControllerRenderProps } from 'react-hook-form';

interface IProps extends Omit<DatePickerProps, 'value' | 'onChange'> {
  field?: ControllerRenderProps<any, any>;
}

export const DatePicker: React.FC<IProps> = ({
  className,
  field,
  ...props
}) => {
  return <DatePickerBase className={className} {...field} {...props} />;
};
