import { notification } from 'antd';
import './style.scss';
export enum NotificationType {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error',
}

export const toastMessage = (type: NotificationType, message?: string) => {
  notification[type]({
    message: message,
  });
};

type ErrorDetail = {
  property: string;
  codes: Record<string, string>;
};

type ErrorResponse = {
  message: ErrorDetail[];
  error: string;
  statusCode: number;
};

export const getMessageSubmitForm = (
  response: ErrorResponse,
): string | undefined => {
  if (response.error === 'FORM_VALIDATION' && response.message.length > 0) {
    const firstMessage = response.message[0];
    const firstCodeKey = Object.keys(firstMessage.codes)[0];
    return firstMessage.codes[firstCodeKey];
  }
  return 'SERVER_ERROR';
};
