import { CompanyApi } from '@/apis';
import { Button, FieldReadOnly } from '@/components/common';
import { useQuery } from '@tanstack/react-query';
import { Col, Divider, Form, Row, Tag, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import {
  genderOptions,
  ROLE_KEY,
  statusColors,
  statusLabels,
} from '@/const/option';
import { getCookie } from '@/utils/storage';
import { APP_ROUTER } from '@/routes/routes';
import { EditFilled, LeftCircleOutlined } from '@ant-design/icons';
import { commonUI } from '@/utils/text/UI';
import { ENDPOINT } from '@/apis/endpoint';

interface DetailCompanyProps {
  companyId?: string;
}

const DetailCompany: React.FC<DetailCompanyProps> = ({ companyId }) => {
  const { id: paramId } = useParams();
  const id = companyId || paramId;
  const navigate = useNavigate();
  const role = getCookie('role') ? JSON.parse(getCookie('role')) : '';

  const { data: companyDetail, isError } = useQuery({
    queryKey: [ENDPOINT.COMPANY.GET_DETAIL.replace(':id', id!)],
    queryFn: async () => CompanyApi.getDetailCompany(Number(id)),
    enabled: !!id,
  });

  return (
    <div>
      <div className='h-full flex flex-col items-center'>
        <Form
          labelCol={{ xs: 20, sm: 8, md: 6, lg: 12, xl: 6 }}
          labelAlign='left'
          className='w-full h-full flex flex-col'
        >
          <div className='bg-white p-6 rounded-lg py-[16px] px-[24px] mb-[10px]'>
            <div className='w-full text-2xl font-semibold'>
              {commonUI.detailCompany}
            </div>
          </div>
          <div className='h-full min-h-[81vh] flex flex-col justify-between bg-white p-6 rounded-lg'>
            <div>
              <div>
                <div className='flex justify-between'>
                  <Typography.Title level={4}>
                    <div className='border-b-2 border-current mb-4'>
                      {commonUI.infoCompany}
                    </div>
                  </Typography.Title>
                  <div>
                    <Button
                      type='primary'
                      block={true}
                      onClick={() =>
                        navigate(
                          APP_ROUTER.COMPANY.UPDATE.replace(':id', String(id)),
                        )
                      }
                      className='w-full max-w-xs'
                      icon={<EditFilled />}
                    >
                      {commonUI.edit}
                    </Button>
                  </div>
                </div>
                <Row gutter={[16, 12]}>
                  <Col xs={24} sm={12}>
                    <FieldReadOnly label={commonUI.companyName}>
                      {companyDetail?.name}
                    </FieldReadOnly>
                  </Col>
                  <Col xs={24} sm={12}>
                    <FieldReadOnly label={commonUI.companyPostCode}>
                      {companyDetail?.postCode}
                    </FieldReadOnly>
                  </Col>
                  <Col xs={24} sm={12}>
                    <FieldReadOnly label={commonUI.email}>
                      {companyDetail?.email}
                    </FieldReadOnly>
                  </Col>
                  <Col xs={24} sm={12}>
                    <FieldReadOnly label={commonUI.phone}>
                      {companyDetail?.phone}
                    </FieldReadOnly>
                  </Col>
                  <Col xs={24} sm={12}>
                    <FieldReadOnly label={commonUI.address}>
                      {companyDetail?.address}
                    </FieldReadOnly>
                  </Col>
                  <Col xs={24} sm={12}>
                    <FieldReadOnly label={commonUI.companyFax}>
                      {companyDetail?.fax}
                    </FieldReadOnly>
                  </Col>
                  <Col xs={24} sm={12}>
                    <FieldReadOnly label={commonUI.status}>
                      <Tag
                        color={
                          statusColors[
                            companyDetail?.status as keyof typeof statusColors
                          ]
                        }
                      >
                        {
                          statusLabels[
                            companyDetail?.status as keyof typeof statusLabels
                          ]
                        }
                      </Tag>
                    </FieldReadOnly>
                  </Col>
                  <Col xs={24} sm={12}>
                    <FieldReadOnly label='ID'>
                      {companyDetail?.code}
                    </FieldReadOnly>
                  </Col>
                </Row>
              </div>
              <Divider />
              <div className='mt-[20px]'>
                <Typography.Title level={4} className='flex'>
                  <div className='border-b-2 border-current mb-4'>
                    {commonUI.infoRep}
                  </div>
                </Typography.Title>
                <Row gutter={[16, 4]}>
                  <Col xs={24} sm={12}>
                    <FieldReadOnly label={commonUI.fullName}>
                      {companyDetail?.users[0]?.fullName}
                    </FieldReadOnly>
                  </Col>
                  <Col xs={24} sm={12}>
                    <FieldReadOnly label={commonUI.gender}>
                      {
                        genderOptions[
                          companyDetail?.users[0]
                            ?.gender as keyof typeof genderOptions
                        ]
                      }
                    </FieldReadOnly>
                  </Col>
                  <Col xs={24} sm={12}>
                    <FieldReadOnly label={commonUI.email}>
                      {companyDetail?.users[0]?.email}
                    </FieldReadOnly>
                  </Col>
                  <Col xs={24} sm={12}>
                    <FieldReadOnly label={commonUI.address}>
                      {companyDetail?.users[0]?.address}
                    </FieldReadOnly>
                  </Col>
                  <Col xs={24} sm={12}>
                    <FieldReadOnly label={commonUI.birthday}>
                      {companyDetail?.users[0]?.dob}
                    </FieldReadOnly>
                  </Col>
                  <Col xs={24} sm={12}>
                    <FieldReadOnly label={commonUI.phone}>
                      {companyDetail?.users[0]?.phone}
                    </FieldReadOnly>
                  </Col>
                </Row>
              </div>
            </div>
            <div className='flex gap-4 w-full mt-[30px] w-[80px]'>
              {role === ROLE_KEY.ADMIN && (
                <Button
                  onClick={() => navigate(APP_ROUTER.COMPANY.LIST)}
                  type='primary'
                  ghost
                  icon={<LeftCircleOutlined />}
                >
                  {commonUI.back}
                </Button>
              )}
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default DetailCompany;
