import { Button, Field } from '@/components/common';
import { useLoading } from '@/states/loading/index.state';
import { Col, Form, Row } from 'antd';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { UserApi } from '@/apis';
import {
  getMessageSubmitForm,
  NotificationType,
  toastMessage,
} from '@/hooks/toastMessage';
import { ErrorMessage, ErrorMessageKeys, MESSAGE } from '@/const/message';
import { InputPassword } from '@/components/common/InputPassword';
import { commonUI } from '@/utils/text/UI/index';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { regex } from '@/utils';
import { messageUI } from '@/utils/text/message';

interface UserForm {
  password: string;
  newPassword: string;
  confirmPassword: string;
}

const validationSchema = Yup.object({
  password: Yup.string()
    .required(messageUI.required)
    .min(10, messageUI.passwordMin)
    .matches(regex.validatePassword, messageUI.passwordInvalid),
  newPassword: Yup.string()
    .required(messageUI.required)
    .min(10, messageUI.newPasswordMin)
    .matches(regex.validatePassword, messageUI.passwordInvalid),
  confirmPassword: Yup.string()
    .required(messageUI.required)
    .oneOf([Yup.ref('newPassword')], messageUI.passwordDoNotMatch),
});

const ChangePasswordInfo: React.FC = () => {
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();
  const methods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit } = methods;

  const mutation = useMutation({
    mutationFn: UserApi.changePassword,
    onSuccess: async () => {
      hideLoading();
      toastMessage(NotificationType.success, MESSAGE.CHANGE_PASSWORD_SUCCESS);
      navigate(-1);
    },
    onError: async (error: { error: string }) => {
      toastMessage(
        NotificationType.error,
        ErrorMessage[error.error as ErrorMessageKeys] ??
          getMessageSubmitForm(error as any),
      );
      hideLoading();
    },
  });
  const onSubmit = async (values: UserForm) => {
    const data = {
      oldPassword: values.password,
      password: values.newPassword,
    };
    mutation.mutate(data);
    showLoading();
  };

  return (
    <div className='h-full'>
      <FormProvider {...methods}>
        <Form
          onFinish={handleSubmit(onSubmit)}
          className='h-full w-full flex flex-col items-center'
          labelAlign='left'
          layout='vertical'
        >
          <div className='w-full bg-white p-6 rounded-lg py-[16px] px-[24px] mb-[10px]'>
            <div className='text-2xl font-semibold'>
              {commonUI.resetPassword}
            </div>
          </div>
          <div className='w-full h-full bg-white p-6 rounded-lg py-[32px] px-[24px]'>
            <Row gutter={[24, 4]} className='w-full'>
              <Col span={24}>
                <Field
                  name='password'
                  label={commonUI.currentPassword}
                  required
                >
                  <InputPassword
                    style={{ width: '100%' }}
                    maxLength={100}
                    type='password'
                    placeholder={messageUI.placeholderInput}
                  />
                </Field>
              </Col>
              <Col span={24}>
                <Field name='newPassword' label={commonUI.newPassword} required>
                  <InputPassword
                    style={{ width: '100%' }}
                    maxLength={100}
                    type='password'
                    placeholder={messageUI.placeholderInput}
                  />
                </Field>
              </Col>
              <Col span={24}>
                <Field
                  name='confirmPassword'
                  label={commonUI.confirmNewPassword}
                  required
                >
                  <InputPassword
                    style={{ width: '100%' }}
                    maxLength={100}
                    type='password'
                    placeholder={messageUI.placeholderInput}
                  />
                </Field>
              </Col>
            </Row>
            <div className='flex justify-center mt-[30px]'>
              <div className='w-[240px]'>
                <Button block={true} type='primary' htmlType='submit'>
                  {commonUI.login}
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </FormProvider>
    </div>
  );
};

export default ChangePasswordInfo;
