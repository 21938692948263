import { CompanyApi } from '@/apis';
import { ContractApi } from '@/apis/contract';
import { ENDPOINT } from '@/apis/endpoint';
import { Button, Field, Modal } from '@/components/common';
import { ErrorMessage, ErrorMessageKeys } from '@/const/message';
import { ROLE_KEY, STATUS_KEY } from '@/const/option';
import {
  getMessageSubmitForm,
  NotificationType,
  toastMessage,
} from '@/hooks/toastMessage';
import { useLoading } from '@/states/loading/index.state';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Col, DatePicker, Form, Input, Row, Select, Typography } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { formSchemaFn } from './formSchemaFn';
import { LeftCircleOutlined } from '@ant-design/icons';
import { getCookie } from '@/utils/storage';
import { APP_ROUTER } from '@/routes/routes';
import { messageUI } from '@/utils/text/message';
import { commonUI } from '@/utils/text/UI';
interface ContractForm {
  id?: number;
  companyId: number;
  expiredAt: string;
  startAt: string;
}

type Company = {
  label: string;
  value?: number;
};

const FormContract: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();
  const [open, setOpen] = useState(false);
  const [listCompany, setListCompany] = useState<Company[]>([]);
  const role = getCookie('role') ? JSON.parse(getCookie('role')) : 'GUEST';

  const { data } = useQuery({
    queryKey: [ENDPOINT.COMPANY.GET_LIST],
    queryFn: () => CompanyApi.getListCompany(),
  });

  const methods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(formSchemaFn()),
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { isValid },
  } = methods;

  const handleSelectCompany = () => {
    let code;
    const selected = data?.data?.find((e) => e.id === watch('companyId'));
    if (selected) {
      code = selected.code;
    }
    return code;
  };

  useEffect(() => {
    if (data) {
      const value = data?.data.map((e) => {
        return {
          label: e.name,
          value: e.id,
        };
      });
      setListCompany(value);
    }
  }, [data]);
  const { data: dataDetailContract } = useQuery({
    queryKey: [ENDPOINT.CONTRACT.GET_DETAIL, Number(id)],
    queryFn: () => ContractApi.getDetailContract(Number(id)),
    enabled: !!id,
  });

  useEffect(() => {
    if (dataDetailContract) {
      const value = {
        startAt: dayjs(dataDetailContract.startAt, 'YYYY-MM-DD'),
        expiredAt: dayjs(dataDetailContract.expiredAt, 'YYYY-MM-DD'),
        companyId: dataDetailContract.company.id,
      };
      reset(value);
    }
  }, [dataDetailContract]);

  const mutation = useMutation({
    mutationFn: ContractApi.createContract,
    onSuccess: async () => {
      hideLoading();
      navigate(APP_ROUTER.CONTRACT.LIST);
      toastMessage(NotificationType.success, messageUI.createSuccess);
    },
    onError: async (error: { error: string }) => {
      hideLoading();
      toastMessage(
        NotificationType.error,
        ErrorMessage[error.error as ErrorMessageKeys] ??
          getMessageSubmitForm(error as any),
      );
    },
  });

  const mutationUpdate = useMutation({
    mutationFn: ContractApi.updateContract,
    onSuccess: async () => {
      hideLoading();
      setOpen(false);
      navigate(APP_ROUTER.CONTRACT.DETAIL.replace(':id', String(id)));
      toastMessage(NotificationType.success, messageUI.updateSuccess);
    },
    onError: async (error: { error: string }) => {
      hideLoading();
      setOpen(false);
      toastMessage(
        NotificationType.error,
        ErrorMessage[error.error as ErrorMessageKeys] ??
          getMessageSubmitForm(error as any),
      );
    },
  });

  const onSubmit = async (values: ContractForm) => {
    showLoading();
    values.startAt = dayjs(values.startAt).format('YYYY-MM-DD');
    values.expiredAt = dayjs(values.expiredAt).format('YYYY-MM-DD');
    if (id) {
      values.id = Number(id);
    }
    id ? mutationUpdate.mutate(values) : mutation.mutate(values);
  };

  return (
    <div>
      <FormProvider {...methods}>
        <Form
          onFinish={handleSubmit(onSubmit)}
          className='w-full h-full flex flex-col'
          labelCol={{ xs: 20, sm: 8, md: 6, lg: 12, xl: 6 }}
          labelAlign='left'
          layout='vertical'
        >
          <div className='bg-white p-6 rounded-lg py-[16px] px-[24px] mb-[10px]'>
            <div className='text-2xl font-semibold'>
              {id ? commonUI.updateContract : commonUI.createContract}
            </div>
          </div>
          <div className='w-full min-h-[81vh] flex flex-col justify-between bg-white p-6 rounded-lg'>
            <div>
              <div className='flex justify-between'>
                <Typography.Title level={4} className='flex'>
                  <div className='border-b-2 text-xl border-current mb-4'>
                    {commonUI.infoContract}
                  </div>
                </Typography.Title>
                {role === ROLE_KEY.ADMIN &&
                  dataDetailContract?.status === STATUS_KEY.IN_ACTIVE && (
                    <div onClick={() => isValid && setOpen(true)}>
                      <Button
                        type='primary'
                        block={true}
                        className='w-full max-w-xs'
                      >
                        {commonUI.update}
                      </Button>
                    </div>
                  )}
                {!id && (
                  <div>
                    <Button
                      htmlType='submit'
                      type='primary'
                      block={true}
                      className='w-full max-w-xs'
                    >
                      {commonUI.create}
                    </Button>
                  </div>
                )}
              </div>
              <div>
                <Row gutter={[64, 4]}>
                  <Col xs={24} lg={12}>
                    <Field name='Company Code' label={commonUI.companyCode}>
                      <Input
                        value={handleSelectCompany()}
                        disabled
                        maxLength={10}
                      />
                    </Field>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Field
                      name='companyId'
                      label={commonUI.companyName}
                      required
                    >
                      <Select
                        placeholder={messageUI.placeholderInput}
                        options={listCompany}
                      />
                    </Field>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Field name='startAt' label={commonUI.startDate} required>
                      <DatePicker
                        placeholder={messageUI.placeholderSelect}
                        format='YYYY-MM-DD'
                        className='w-full'
                      />
                    </Field>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Field
                      name='expiredAt'
                      label={commonUI.expiredDate}
                      required
                    >
                      <DatePicker
                        placeholder={messageUI.placeholderSelect}
                        format='YYYY-MM-DD'
                        className='w-full'
                      />
                    </Field>
                  </Col>
                </Row>
              </div>
            </div>
            <div className='flex gap-4 w-full mt-[30px] w-[80px]'>
              <Button
                onClick={() =>
                  id
                    ? navigate(
                        APP_ROUTER.CONTRACT.DETAIL.replace(':id', String(id)),
                      )
                    : navigate(APP_ROUTER.CONTRACT.LIST)
                }
                type='primary'
                ghost
                icon={<LeftCircleOutlined />}
              >
                {commonUI.back}
              </Button>
            </div>
          </div>
        </Form>
      </FormProvider>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title={commonUI.confirm}
        description={messageUI.update}
      >
        <div className='flex justify-center gap-[10px] p-[20px] bg-[#E6FAFD]'>
          <Button
            onClick={() => setOpen(false)}
            className='w-full lg:w-[200px] bg-[#D6D9E0] hover:!bg-[#D9DDE6] text-gray-500 hover:!text-gray-500'
            type='primary'
          >
            {commonUI.no}
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            className='w-full lg:w-[200px]'
            type='primary'
            disabled={mutationUpdate.isPending}
          >
            {commonUI.yes}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default FormContract;
