import axiosClient from '../axiosClient';

interface ILogin {
  email: string;
  password: string;
}
interface IForgotPassword {
  email: string;
}
interface IResetPassword {
  password: string;
  codeVerify?: string;
}
interface IChangePassword {
  password: string;
  oldPassword: string;
}
interface IInfo {
  fullName: string;
  email: string;
  address: string;
  phone: string;
  dob: string | null;
  gender: string;
}
class User {
  login(data: ILogin) {
    return axiosClient.post('/auth/sign-in', data).then((res) => res.data.data);
  }
  forgotPassword(data: IForgotPassword) {
    return axiosClient
      .post('/auth/forgot-password', data)
      .then((res) => res.data.data);
  }
  resetPassword(data: IResetPassword) {
    return axiosClient
      .post('/auth/reset-password', data)
      .then((res) => res.data.data);
  }
  getMe() {
    return axiosClient.get('/users/me').then((res) => res.data.data);
  }
  getProfile() {
    return axiosClient.get('/users/profile').then((res) => res.data.data);
  }
  updateInfo(data: IInfo) {
    return axiosClient
      .post('/users/update-info', data)
      .then((res) => res.data.data);
  }
  changePassword(data: IChangePassword) {
    return axiosClient
      .post('/users/update-password', data)
      .then((res) => res.data.data);
  }
}

export const UserApi = new User();
