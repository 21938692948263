import { deleteCookie, getCookie } from '@/utils/storage';
import axios from 'axios';

export const envApiUrl =
  process.env.REACT_APP_API_URL || `${document.location.origin}/api/`;
const defaultApiUrl = `${window.location.protocol}//${window.location.hostname}`;
const apiUrl = envApiUrl || defaultApiUrl;

const axiosClient = axios.create({
  baseURL: `${apiUrl}`,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});
axiosClient.interceptors.request.use(async (config: any) => {
  const customHeaders = {
    Authorization: '',
  };
  const token = getCookie('accessToken');

  if (token) {
    customHeaders.Authorization = `Bearer ${token}`;
  }

  return {
    ...config,
    headers: {
      ...customHeaders, // auto attach token
      ...config.headers, // but you can override for some requests
    },
  };
});
axiosClient.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error: any) => {
    if (error.response.status === 401) {
      deleteCookie('accessToken');
      deleteCookie('currentUser');
      deleteCookie('role');
      window.location.href = '/login';
    } else if (error.response.status === 404) {
      window.location.href = '/404';
    } else {
      throw error.response.data;
    }
  },
);

export default axiosClient;
