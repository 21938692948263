import { Layout } from 'antd';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import SidebarComponent from './Sidebar';

const LayoutComponent: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [openDraw, setOpenDraw] = useState<boolean>(false);

  const layoutStyle = {
    overflow: 'hidden',
    height: '100vh',
    backgroundColor: 'linear-gradient(#ffffff, #f5f5f5 28%) !important',
  };

  const contentStyle = {
    overflow: 'scroll',
    backgroundColor: 'linear-gradient(#ffffff, #f5f5f5 28%) !important',
  };

  return (
    <Layout style={layoutStyle}>
      <Header setOpenDraw={setOpenDraw} setCollapsed={setCollapsed} />
      <Layout>
        <SidebarComponent
          collapsed={collapsed}
          openDraw={openDraw}
          setCollapsed={setCollapsed}
          setOpenDraw={setOpenDraw}
        />
        <Layout.Content style={contentStyle} className='px-[20px] py-[20px]'>
          <Outlet />
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default LayoutComponent;
