import { UserApi } from '@/apis';
import { Button, Field } from '@/components/common';
import { ErrorMessage, ErrorMessageKeys, MESSAGE } from '@/const/message';
import {
  getMessageSubmitForm,
  NotificationType,
  toastMessage,
} from '@/hooks/toastMessage';
import { useLoading } from '@/states/loading/index.state';
import { commonUI } from '@/utils/text/UI/index';
import { useMutation } from '@tanstack/react-query';
import { Divider, Form } from 'antd';
import { ReactElement, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { regex } from '@/utils';
import { InputPassword } from '@/components/common/InputPassword';
import { APP_ROUTER } from '@/routes/routes';
import { messageUI } from '@/utils/text/message';

interface ChangePassWordForm {
  newPassword: string;
  confirmPassword: string;
}

const validationSchema = Yup.object({
  newPassword: Yup.string()
    .required(messageUI.newPasswordRequired)
    .min(10, messageUI.newPasswordMin)
    .max(100, messageUI.newPasswordMax)
    .matches(regex.validatePassword, messageUI.passwordInvalid),
  confirmPassword: Yup.string()
    .required(messageUI.confirmNewPasswordRequired)
    .oneOf([Yup.ref('newPassword')], messageUI.passwordDoNotMatch),
});

const ChangePassword: React.FC = (): ReactElement => {
  const methods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit } = methods;
  const { showLoading, hideLoading } = useLoading();
  const navigate = useNavigate();
  const [completed, setCompleted] = useState(false);
  const { code } = useParams();

  const mutation = useMutation({
    mutationFn: UserApi.resetPassword,
    onSuccess: async () => {
      hideLoading();
      setCompleted(true);
      toastMessage(NotificationType.success, MESSAGE.RESET_PASSWORD_SUCCESS);
    },
    onError: async (error: { error: string }) => {
      hideLoading();
      toastMessage(
        NotificationType.error,
        ErrorMessage[error.error as ErrorMessageKeys] ??
          getMessageSubmitForm(error as any),
      );
    },
  });

  const onSubmit = async (values: ChangePassWordForm) => {
    showLoading();
    const data = {
      password: values.newPassword,
      codeVerify: code,
    };
    mutation.mutate(data);
  };

  return (
    <div className='flex items-center bg-[#595959] h-screen'>
      {!completed ? (
        <div className='p-8 m-0 m-auto w-[640px] bg-white rounded'>
          <div className='mb-[10px]'>
            <div
              className={
                'flex items-center text-xl justify-center font-semibold'
              }
            >
              {commonUI.resetPassword}
            </div>
          </div>
          <Divider />
          <FormProvider {...methods}>
            <Form
              onFinish={handleSubmit(onSubmit)}
              className='flex flex-col items-center'
              labelAlign='left'
              layout='vertical'
            >
              <div className='w-full'>
                <Field name='newPassword' label={commonUI.newPassword} required>
                  <InputPassword maxLength={100} type='password' />
                </Field>
                <Field
                  name='confirmPassword'
                  label={commonUI.confirmNewPassword}
                  required
                >
                  <InputPassword maxLength={100} type='password' />
                </Field>
              </div>
              <Divider />
              <div className='w-full flex gap-[10px]'>
                <Button
                  block={true}
                  type='primary'
                  htmlType='submit'
                  onClick={() => navigate(APP_ROUTER.AUTH.LOGIN)}
                >
                  {commonUI.backToLogin}
                </Button>
                <Button block={true} type='primary' htmlType='submit'>
                  {commonUI.setNewPassword}
                </Button>
              </div>
            </Form>
          </FormProvider>
        </div>
      ) : (
        <div className='p-8 m-0 m-auto w-[640px] bg-white rounded'>
          <div className='flex flex-col items-center px-[100px] gap-[30px]'>
            <div className='flex items-center text-xl justify-center font-semibold'>
              {commonUI.setNewPasswordCompleted}
            </div>
            <div className='flex items-center justify-center'>
              <img src='/images/completed.png' />
            </div>
            <div>
              パスワードの再設定が完了いたしました。
              <br />
              新しいパスワードで再度ログインしてください。
            </div>
            <Button
              block={true}
              type='primary'
              htmlType='submit'
              onClick={() => navigate(APP_ROUTER.AUTH.LOGIN)}
            >
              {commonUI.login}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChangePassword;
