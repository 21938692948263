import axiosClient from '../axiosClient';
import { ENDPOINT } from '../endpoint';

interface ContractForm {
  id?: number;
  companyId: number;
  expiredAt: string;
  startAt: string;
}

class Contract {
  getListContract(params?: { page: number; pageSize: number }): Promise<any> {
    return axiosClient
      .get(ENDPOINT.CONTRACT.GET_LIST, { params })
      .then((res) => res.data.data);
  }
  getDetailContract(id: number): Promise<any> {
    return axiosClient
      .get(ENDPOINT.CONTRACT.GET_DETAIL.replace(':id', String(id)))
      .then((res) => res.data.data);
  }
  createContract(data: ContractForm): Promise<any> {
    return axiosClient
      .post(ENDPOINT.CONTRACT.CREATE, data)
      .then((res) => res.data.data);
  }
  updateContract(data: ContractForm): Promise<any> {
    return axiosClient
      .put(ENDPOINT.CONTRACT.UPDATE.replace(':id', String(data.id)), data)
      .then((res) => res.data.data);
  }
  changeToken(id: number, type: string): Promise<any> {
    return axiosClient
      .post(ENDPOINT.CONTRACT.CHANGE_TOKEN.replace(':id', String(id)), { type })
      .then((res) => res.data.data);
  }
  applyToken(id: number, type: string): Promise<any> {
    return axiosClient
      .post(ENDPOINT.CONTRACT.APPLY_TOKEN.replace(':id', String(id)), { type })
      .then((res) => res.data.data);
  }
}

export const ContractApi = new Contract();
