import { UserApi } from '@/apis';
import { useLoading } from '@/states/loading/index.state';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Col, Form, DatePicker, Input, Row, Select } from 'antd';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import {
  getMessageSubmitForm,
  NotificationType,
  toastMessage,
} from '@/hooks/toastMessage';
import { regex } from '@/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ErrorMessage, ErrorMessageKeys, MESSAGE } from '@/const/message';
import { Button, Field } from '@/components/common';
import { convertObjectToArray } from '@/utils/helpers';
import { genderOptions } from '@/const/option';
import { commonUI } from '@/utils/text/UI';
import { messageUI } from '@/utils/text/message';

interface UserForm {
  fullName: string;
  email: string;
  address: string;
  phone: string;
  dob: Dayjs;
  gender: string;
}

const UserInfo: React.FC = () => {
  const { showLoading, hideLoading } = useLoading();
  const navigate = useNavigate();

  const { data, refetch } = useQuery({
    queryKey: ['user'],
    queryFn: UserApi.getProfile,
  });

  const validationSchema = Yup.object({
    fullName: Yup.string().required(messageUI.required),
    phone: Yup.string()
      .nullable()
      .test((value) => {
        if (!value) return true;
        return regex.validatePhone.test(value);
      }),
  });
  const methods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, reset } = methods;

  const mutation = useMutation({
    mutationFn: UserApi.updateInfo,
    onSuccess: async () => {
      hideLoading();
      refetch();
      toastMessage(NotificationType.success, MESSAGE.UPDATE_SUCCESS);
    },
    onError: async (error: { error: string }) => {
      hideLoading();
      toastMessage(
        NotificationType.error,
        ErrorMessage[error.error as ErrorMessageKeys] ??
          getMessageSubmitForm(error as any),
      );
    },
  });
  const onSubmit = async (values: UserForm) => {
    const dataForm = {
      ...values,
      fullName: values.fullName,
      address: values.address,
      phone: values.phone,
      gender: values.gender,
      ...(values.dob
        ? { dob: values.dob.format('YYYY-MM-DD') }
        : { dob: null }),
    };
    showLoading();
    mutation.mutate(dataForm);
  };

  useEffect(() => {
    if (data) {
      const value = {
        fullName: data.fullName,
        email: data.email,
        address: data.address,
        phone: data.phone,
        gender: data.gender,
        dob: data.dob !== null ? dayjs(data.dob, 'YYYY-MM-DD') : null,
      };
      reset(value);
    }
  }, [data]);

  return (
    <div className='h-full'>
      <FormProvider {...methods}>
        <Form
          onFinish={handleSubmit(onSubmit)}
          className='h-full w-full flex flex-col items-center'
          layout='vertical'
        >
          <div className='w-full bg-white p-6 rounded-lg py-[16px] px-[24px] mb-[10px]'>
            <div className='text-2xl font-semibold'>{commonUI.info}</div>
          </div>
          <div className='w-full h-full bg-white p-6 rounded-lg py-[32px] px-[24px]'>
            <Row gutter={[24, 4]} className='w-full'>
              <Col span={24}>
                <Field name='fullName' label={commonUI.fullName} required>
                  <Input maxLength={60} />
                </Field>
              </Col>
              <Col span={24}>
                <Field name='email' label={commonUI.email} required>
                  <Input disabled={true} />
                </Field>
              </Col>
              <Col span={24}>
                <Field name='address' label={commonUI.address}>
                  <Input
                    placeholder={messageUI.placeholderInput}
                    maxLength={200}
                  />
                </Field>
              </Col>
              <Col span={24}>
                <Field name='phone' label={commonUI.phone}>
                  <Input
                    placeholder={messageUI.placeholderInput}
                    maxLength={11}
                  />
                </Field>
              </Col>
              <Col span={24}>
                <Field name='gender' label={commonUI.gender}>
                  <Select options={convertObjectToArray(genderOptions)} />
                </Field>
              </Col>
              <Col span={24}>
                <Field name='dob' label={commonUI.birthday}>
                  <DatePicker
                    placeholder={messageUI.placeholderSelect}
                    format='YYYY-MM-DD'
                    className='w-full'
                  />
                </Field>
              </Col>
              <Col span={24}>
                <div className='flex justify-center gap-4'>
                  <div className='w-[240px]'>
                    <Button block={true} type='primary' htmlType='submit'>
                      {commonUI.update}
                    </Button>
                  </div>
                  <div className='w-[240px]'>
                    <Button block={true} onClick={() => navigate('/company')}>
                      {commonUI.back}
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      </FormProvider>
    </div>
  );
};

export default UserInfo;
