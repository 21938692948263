import { Table as TableBase, TableProps } from 'antd';

export const Table: React.FC<TableProps<any>> = ({
  className,
  pagination = false,
  bordered = true,
  ...props
}) => {
  return (
    <TableBase
      className={className}
      pagination={pagination}
      bordered={bordered}
      {...props}
    />
  );
};
