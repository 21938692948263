import { Button, Modal as ModalBase } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import './style.scss';
interface IProps {
  title: string;
  description?: string;
  onOk?: () => void;
  onCancel?: () => void;
  open: boolean;
  children: React.ReactNode;
  className?: string;
  width?: number;
}

export const Modal: React.FC<IProps> = ({
  title,
  description,
  open,
  onOk,
  onCancel,
  children,
  className,
  width,
}) => {
  return (
    <ModalBase
      width={width}
      className={className}
      title={
        <div>
          <div className='flex justify-between h-[55px] font-semibold text-2xl bg-[#3BB8C6]'>
            <div className='pt-[10px] ml-[10px] text-white'>{title}</div>
            <div className='pt-[5px] mr-[10px]'>
              <Button
                onClick={onCancel}
                type='primary'
                icon={<CloseCircleOutlined />}
              />
            </div>
          </div>
          {description && (
            <div className='my-[30px] text-center text-gray-500'>
              {description}
            </div>
          )}
        </div>
      }
      closable={false}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      maskClosable={false}
      footer={null}
    >
      {children}
    </ModalBase>
  );
};
