import { createContext, useContext, useState } from "react";

interface LoadingContextData {
  isLoading: boolean;
  showLoading: () => void;
  hideLoading: () => void;
}

interface LoadingProviderProps {
  children: React.ReactNode;
}

const LoadingContext = createContext<LoadingContextData>({
  isLoading: false,
  showLoading: () => {},
  hideLoading: () => {},
});

const LoadingProvider = ({ children }: LoadingProviderProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const showLoading = () => {
    setIsLoading(true);
  };

  const hideLoading = () => {
    setIsLoading(false);
  };

  return (
    <LoadingContext.Provider
      value={{
        isLoading,
        showLoading,
        hideLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

const useLoading = () => {

  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error("useLoading wrong");
  }
  return context;
};

export { LoadingProvider, useLoading };
