export const MESSAGE = {
  CREATE_SUCCESS: 'Create success',
  UPDATE_SUCCESS: 'Update success',
  CREATE_FAIL: 'Create fail',
  UPDATE_FAIL: 'Update fail',
  SERVER_ERROR: 'Server error',
  LOGIN_SUCCESS: 'Login success',
  FORGOT_PASSWORD_SUCCESS:
    'リンク付きのメールが送信されました。メールを確認してください。',
  RESET_PASSWORD_SUCCESS: 'パスワードのリセットに成功しました。',
  CHANGE_PASSWORD_SUCCESS: 'Changed password success',
  EXPIRED_CODE: 'The verification code has expired',
  OLD_PASSWORD_INCORRECT: 'Old password incorrect',
  EMPTY_VALUE: 'cannot be empty or whitespace only',
};
export const ErrorMessage = {
  SERVER_ERROR: 'Server error',
  INVALID:
    'Invalid input data. Please check the provided information and try again.',
  EMAIL_CONFLICT: 'Email is already in use',
  EXPIRED_CODE: 'The verification code has expired',
  PASSWORD_TO_WEAK: 'Password too weak',
  USER_NOT_FOUND: 'user not found',
  INCORRECT_LOGIN: 'メールアドレスまたはパスワードが正しくありません。',
  NAME_INVALID: 'The name is not in the correct format',
  GAME_NOT_FOUND: 'Game not found',
  TOKEN_INVALID: 'token invalid',
  TOKEN_EXPIRED: 'Token expired',
  ROLE_NOT_FOUND: 'Role not found',
  USER_ID_INVALID: 'User id invalid',
  WRONG_PASSWORD: 'Password incorrect',
  COMPANY_NOT_FOUND: 'Company not exist',
  CONTRACT_NOT_FOUND: 'Contract not exist',
  SCORE_INDEX_NOT_FOUND: 'Score index not exist',
  CONTRACT_IS_INVALID: 'Contract date must be after the expiration date',
} as const;

export type ErrorMessageKeys = keyof typeof ErrorMessage;
