import { IOption, OptionsObject } from '@/types/common';

export const convertObjectToArray = (
  optionsObject: OptionsObject,
  idType: 'number' | 'string' = 'number',
) => {
  return Object.entries(optionsObject).map(([value, label]) => ({
    value: idType === 'number' ? parseInt(value) : value,
    label,
  }));
};

export const convertArrayToOptions = (
  arrs: any[],
  valueKey: string,
  labelKey: string,
  idType: 'number' | 'string' = 'number',
): IOption[] => {
  const getValueById = (
    obj: any,
    idType: 'number' | 'string',
  ): number | string => {
    switch (idType) {
      case 'number':
        return Number(obj[valueKey]);
      case 'string':
        return String(obj[valueKey]);
      default:
        return Number(obj[valueKey]);
    }
  };

  if (Array.isArray(arrs)) {
    return arrs?.map((obj) => ({
      value: getValueById(obj, idType),
      label: obj[labelKey],
    }));
  }

  return [];
};

export const convertRequest = <T extends object>(value: T): T => {
  return Object.fromEntries(
    Object.entries(value).map(([key, val]) => [
      key,
      val === '' ? undefined : val,
    ]),
  ) as T;
};
