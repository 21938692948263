import { Select as SelectBase } from 'antd';
import { SelectProps } from 'antd/lib';
import { ControllerRenderProps } from 'react-hook-form';

interface IInputWrapProps extends SelectProps {
  field?: ControllerRenderProps<any, any>;
}

export const Select: React.FC<IInputWrapProps> = ({
  className,
  options,
  field,
  ...props
}) => {
  return (
    <SelectBase className={className} {...field} {...props}>
      {(options ?? []).map((item) => {
        return (
          <SelectBase.Option key={item?.value} value={item?.value}>
            {item.label}
          </SelectBase.Option>
        );
      })}
    </SelectBase>
  );
};
