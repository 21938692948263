import { InputNumber as InputNumberBase, InputNumberProps } from 'antd';
import { ControllerRenderProps } from 'react-hook-form';

interface IInputNumberWrapProps extends InputNumberProps {
  field?: ControllerRenderProps<any, any>;
}

export const InputNumber: React.FC<IInputNumberWrapProps> = ({
  className,
  field,
  ...props
}) => {
  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const specialCharRegex = new RegExp('[0-9]');
    const pressedKey = String.fromCharCode(!e.charCode ? e.which : e.charCode);

    if (!specialCharRegex.test(pressedKey) || pressedKey === ' ') {
      e.preventDefault();
      return;
    }
  };

  return (
    <InputNumberBase
      className={className}
      onKeyPress={onKeyPress}
      inputMode='none'
      controls={false}
      pattern='[0-9]*'
      {...field}
      {...props}
    />
  );
};
