import { Spin as SpinBase, SpinProps } from 'antd';

interface IProps extends SpinProps {
  children?: React.ReactNode;
}
export const Spin: React.FC<IProps> = ({ className, children, ...props }) => {
  return (
    <SpinBase className={className} {...props}>
      {children}
    </SpinBase>
  );
};
