import { useAuth } from '@/states/auth/index.state';
import { Avatar, Upload } from 'antd';

export const UploadImage = ({}: any) => {
  const { currentUser } = useAuth();
  return (
    <div className="flex justify-center">
      <Upload
        name="avatar"
        listType="picture-card"
        showUploadList={false}
        accept=".jpg,.jpeg,.png"
      >
        <div>
          <figure>
            <Avatar
              style={{ backgroundColor: '#f56a00', verticalAlign: 'middle' }}
              size="large"
              gap={2}
            >
              {currentUser?.email?.charAt(0).toUpperCase()}
            </Avatar>
          </figure>
        </div>
      </Upload>
    </div>
  );
};
