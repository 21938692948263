import { APP_ROUTER } from '@/routes/routes';
import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';

const NotFound: React.FC = () => {
  return (
    <Result
      status='404'
      title='404'
      subTitle='The resource you are looking for could not be found. Please check the URL and try again.'
      extra={
        <Link to={APP_ROUTER.HOME}>
          <Button type='primary'>Back Home</Button>
        </Link>
      }
    />
  );
};
export default NotFound;
