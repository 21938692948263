import { Checkbox } from 'antd';
import { CheckboxGroupProps } from 'antd/es/checkbox';
import { ControllerRenderProps } from 'react-hook-form';

interface IProps extends CheckboxGroupProps {
  field?: ControllerRenderProps<any, any>;
}

export const CheckboxGroup: React.FC<IProps> = ({
  className,
  field,
  ...props
}) => {
  return <Checkbox.Group className={className} {...field} {...props} />;
};
