import { Modal, Spin } from 'antd';
import './style.scss';

interface IProps {
  loading: boolean;
}

export const LoadingModal: React.FC<IProps> = ({ loading }) => {
  return (
    <Modal
      className='loading-content'
      visible={loading}
      footer={null}
      closable={false}
      centered
    >
      <Spin />
    </Modal>
  );
};
