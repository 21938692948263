// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-typography-ellipsis {
  white-space: pre-line;
}

.ant-table-wrapper .ant-table {
  scrollbar-color: unset;
}

.no-select {
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.pagination-table {
  display: flex;
  justify-content: flex-end;
  padding: 16px 0;
}

.pre-line {
  white-space: pre-line;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}`, "",{"version":3,"sources":["webpack://./src/styles/base/_antd.scss","webpack://./src/styles/global.scss","webpack://./src/styles/base/_common.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;ACCF;;ADEA;EACE,sBAAA;ACCF;;ACNA;EACE,yBAAA;EAIA,oBAAA;EACA,iBAAA;ADSF;;ACNA;EACE,aAAA;EACA,yBAAA;EACA,eAAA;ADSF;;ACNA;EACE,qBAAA;ADSF;;ACNA;EACE,UAAA;EACA,WAAA;ADSF;;ACNA;EACE,gBAAA;EACA,kBAAA;ADSF;;ACNA;EACE,gBAAA;ADSF","sourcesContent":[".ant-typography-ellipsis {\n  white-space: pre-line;\n}\n\n.ant-table-wrapper .ant-table {\n  scrollbar-color: unset;\n}\n",".ant-typography-ellipsis {\n  white-space: pre-line;\n}\n\n.ant-table-wrapper .ant-table {\n  scrollbar-color: unset;\n}\n\n.no-select {\n  -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  -o-user-select: none;\n  user-select: none;\n}\n\n.pagination-table {\n  display: flex;\n  justify-content: flex-end;\n  padding: 16px 0;\n}\n\n.pre-line {\n  white-space: pre-line;\n}\n\n::-webkit-scrollbar {\n  width: 6px;\n  height: 6px;\n}\n\n::-webkit-scrollbar-thumb {\n  background: #888;\n  border-radius: 5px;\n}\n\n::-webkit-scrollbar-thumb:hover {\n  background: #555;\n}",".no-select {\n  -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  -o-user-select: none;\n  user-select: none;\n}\n\n.pagination-table {\n  display: flex;\n  justify-content: flex-end;\n  padding: 16px 0;\n}\n\n.pre-line {\n  white-space: pre-line;\n}\n\n::-webkit-scrollbar {\n  width: 6px;\n  height: 6px;\n}\n\n::-webkit-scrollbar-thumb {\n  background: #888;\n  border-radius: 5px;\n}\n\n::-webkit-scrollbar-thumb:hover {\n  background: #555;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
