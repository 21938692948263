import { Pagination as PaginationBase, PaginationProps } from 'antd';

const showTotal: PaginationProps['showTotal'] = (total) =>
  `Total ${total} items`;

export const Pagination: React.FC<PaginationProps> = ({
  className,
  ...props
}) => {
  return (
    <PaginationBase className={className} showTotal={showTotal} {...props} />
  );
};
