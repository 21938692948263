export const commonUI = {
  info: '情報',
  email: 'メールアドレス',
  password: 'パスワード',
  login: 'ログイン',
  logout: 'ログアウト',
  backToLogin: 'ログインに戻る',
  send: '送信',
  yes: 'はい',
  no: 'いいえ',
  confirm: '確認',
  detail: '詳細',
  address: '住所',
  phone: '電話番号',
  contract: '契約',
  company: '会社',
  dashboard: 'ダッシュボード',
  settingInfo: 'プロフィール設定',
  forgotPassword: 'パスワードをお忘れの方',
  resetPassword: 'パスワード再設定',
  changePassword: 'パスワード再設定',
  currentPassword: '現在のパスワード',
  newPassword: '新しいパスワード',
  confirmNewPassword: '新しいパスワード（確認）',
  setNewPassword: '新しいパスワードを設定',
  setNewPasswordCompleted: 'パスワード再設定完了',
  listContract: '契約一覧',
  createContract: '契約新規作成',
  detailContract: '契約詳細',
  updateContract: '契約編集',
  infoContract: '契約情報',
  listCompany: '会社一覧',
  createCompany: '会社新規作成',
  updateCompany: '会社編集',
  detailCompany: '会社詳細',
  infoCompany: '会社情報',
  infoRep: '代表者情報',
  token: 'トークン',
  tokenMaster: 'トークンマスター',
  tokenNormal: 'トークンノーマル',
  tokenHistory: 'トークン履歴',
  approvalDate: '承認日付',
  register: '新規登録',
  edit: '編集',
  create: '登録',
  update: '更新',
  back: '戻る',
  history: '歴史',
  resetToken: 'トークン再設定',
  applyToken: 'トークン適用',
  curToken: '現在のトークン',
  newToken: '新しいトークン',
  companyName: '会社名',
  companyCode: '会社コード',
  companyPostCode: '郵便番号',
  companyFax: 'FAX',
  fullName: '氏名',
  birthday: '生年月日',
  startDate: '開始日',
  createDate: '作成日',
  endDate: '終了日',
  gender: '性別',
  expiredDate: '有効期限',
  status: 'ステータス',
};
