import { ReactNode } from "react";
import { AuthProvider } from "./auth/index.state";
import { StateGlobalProvider } from "./global/index.state";
import { LoadingProvider } from "./loading/index.state";

const StatesProvider = ({ children }: { children: ReactNode }) => {
  return (
    <StateGlobalProvider>
      <LoadingProvider>
        <AuthProvider>{children}</AuthProvider>
      </LoadingProvider>
    </StateGlobalProvider>
  );
};

export default StatesProvider;
