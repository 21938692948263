import { IResponse } from '@/types/common';
import { IGetAllTaskResponse } from '@/types/response/task';
import axiosClient from '../axiosClient';
import { ENDPOINT } from '../endpoint';

class Task {
  getAll = (): Promise<IResponse<IGetAllTaskResponse>> => {
    return axiosClient.get(ENDPOINT.TASK.GET_ALL).then((res) => res.data);
  };
}

export const TaskApi = new Task();
