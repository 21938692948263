import React from 'react';
import { Form, Tag } from 'antd';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import './style.scss';

interface IProps {
  name: string;
  label?: string;
  className?: string;
  children: React.ReactNode;
  required?: boolean;
}

export const Field: React.FC<IProps> = ({
  name,
  label,
  className,
  children,
  required,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = errors[name as any] as FieldError;

  return (
    <Form.Item
      label={
        <>
          <>{label}</>
          <>
            {required && <Tag className='p-[2px] ml-[5px]' color='#FF4D4F' />}
          </>
        </>
      }
      validateStatus={error ? 'error' : ''}
      help={error ? error.message : null}
      rootClassName={clsx('c-field', className)}
    >
      <Controller
        name={name!}
        control={control}
        render={({ field }) => {
          if (React.isValidElement(children)) {
            return React.cloneElement(children, {
              ...field,
              ...children?.props,
            });
          }

          return <></>;
        }}
      />
    </Form.Item>
  );
};
