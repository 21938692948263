import { UserApi } from '@/apis';
import { Button, Field } from '@/components/common';
import { ErrorMessage, ErrorMessageKeys } from '@/const/message';
import {
  getMessageSubmitForm,
  NotificationType,
  toastMessage,
} from '@/hooks/toastMessage';
import { useAuth } from '@/states/auth/index.state';
import { useLoading } from '@/states/loading/index.state';
import { regex } from '@/utils';
import { useMutation } from '@tanstack/react-query';
import { Col, Form, Input, Row } from 'antd';
import { ReactElement, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { commonUI } from '@/utils/text/UI/index';
import { InputPassword } from '@/components/common/InputPassword';
import { messageUI } from '@/utils/text/message';

interface LoginForm {
  email: string;
  password: string;
}

const validationSchema = Yup.object({
  email: Yup.string()
    .required(messageUI.emailRequired)
    .matches(regex.validateEmail, messageUI.emailInvalid),
  password: Yup.string()
    .required(messageUI.passwordRequired)
    .min(10, messageUI.passwordMin),
});

const Login: React.FC = (): ReactElement => {
  const methods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, reset } = methods;
  const { logInSuccess } = useAuth();
  const { showLoading, hideLoading } = useLoading();
  const mutation = useMutation({
    mutationFn: UserApi.login,
    onSuccess: async (data) => {
      hideLoading();
      logInSuccess({
        accessToken: data.accessToken,
        resultUser: { email: data.email, id: data.id },
        roles: data.roles,
        ...(data.company && { company: data.company }),
      });
    },
    onError: async (error: { error: string }) => {
      hideLoading();
      toastMessage(
        NotificationType.error,
        ErrorMessage[error.error as ErrorMessageKeys] ??
          getMessageSubmitForm(error as any),
      );
    },
  });

  const onSubmit = async (values: LoginForm) => {
    showLoading();
    mutation.mutate(values);
  };

  return (
    <div className='flex items-center bg-[#595959] h-screen'>
      <div className='p-8 m-0 m-auto w-[440px] bg-white rounded'>
        <div className='mb-[10px]'>
          <div
            className={'flex items-center text-xl justify-center font-semibold'}
          >
            {commonUI.login}
          </div>
        </div>
        <FormProvider {...methods}>
          <Form
            onFinish={handleSubmit(onSubmit)}
            className='flex flex-col items-center'
            labelAlign='left'
            layout='vertical'
          >
            <Row gutter={[24, 4]}>
              <Col span={24}>
                <Field name='email' label={commonUI.email} required>
                  <Input
                    style={{ width: '100%' }}
                    placeholder='Email'
                    maxLength={64}
                  />
                </Field>
              </Col>
              <Col span={24}>
                <Field name='password' label={commonUI.password} required>
                  <InputPassword
                    style={{ width: '100%' }}
                    placeholder='Password'
                    maxLength={100}
                    type='password'
                  />
                </Field>
              </Col>
            </Row>
            <Link
              className='border-solid border-b border-blue-500 mb-[20px]'
              to='/forgot-password'
            >
              <span className='text-blue-500'>{commonUI.forgotPassword}</span>
            </Link>
            <Button block={true} type='primary' htmlType='submit'>
              {commonUI.login}
            </Button>
          </Form>
        </FormProvider>
      </div>
    </div>
  );
};

export default Login;
