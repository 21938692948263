// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-content .ant-modal-content {
  background-color: unset;
  box-shadow: unset;
}
.loading-content .ant-modal-content .ant-modal-body {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/common/LoadingModal/style.scss"],"names":[],"mappings":"AACE;EACE,uBAAA;EACA,iBAAA;AAAJ;AACI;EACE,kBAAA;AACN","sourcesContent":[".loading-content {\n  .ant-modal-content {\n    background-color: unset;\n    box-shadow: unset;\n    .ant-modal-body {\n      text-align: center;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
