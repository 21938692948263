import { Button, FieldReadOnly, Modal } from '@/components/common';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Col,
  Divider,
  Form,
  Row,
  Table,
  TableColumnsType,
  Tag,
  Typography,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { EditFilled, LeftCircleOutlined } from '@ant-design/icons';
import {
  getMessageSubmitForm,
  NotificationType,
  toastMessage,
} from '@/hooks/toastMessage';
import { useState } from 'react';
import { ErrorMessage, ErrorMessageKeys } from '@/const/message';
import { ContractApi } from '@/apis/contract';
import dayjs from 'dayjs';
import { getCookie } from '@/utils/storage';
import {
  formatDate,
  ROLE_KEY,
  statusColors,
  statusLabels,
  STATUS_KEY,
} from '@/const/option';
import { ENDPOINT } from '@/apis/endpoint';
import { APP_ROUTER } from '@/routes/routes';
import { commonUI } from '@/utils/text/UI';
import { messageUI } from '@/utils/text/message';

interface DetailContractProps {
  contractId?: string;
}

const DetailContract: React.FC<DetailContractProps> = ({ contractId }) => {
  const { id: paramId } = useParams();
  const id = contractId || paramId;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState<string>('');
  const [openHistories, setOpenHistories] = useState(false);
  const role = getCookie('role') ? JSON.parse(getCookie('role')) : 'GUEST';

  const { data: dataDetailContract, refetch } = useQuery({
    queryKey: [ENDPOINT.CONTRACT.GET_DETAIL, Number(id)],
    queryFn: () => ContractApi.getDetailContract(Number(id)),
    enabled: !!id,
  });

  const convertHistories = () => {
    return dataDetailContract?.contractHistories?.map(
      (e: { id: number; data: string; updatedAt: string }) => {
        const token = JSON.parse(e?.data)?.token;
        const tokenMaster = JSON.parse(e?.data)?.tokenMaster;
        const time = dayjs(e?.updatedAt).format(formatDate.FULL_TIME);
        return {
          id: e.id,
          time: time,
          token: token,
          tokenMaster: tokenMaster,
        };
      },
    );
  };

  const mutationChangeToken = useMutation({
    mutationFn: ({ id, type }: { id: number; type: string }) =>
      ContractApi.changeToken(id, type),
    onSuccess: async () => {
      setOpen(false);
      refetch();
      toastMessage(NotificationType.success, 'Change Token Success');
    },
    onError: async (error: { error: string }) => {
      toastMessage(
        NotificationType.error,
        ErrorMessage[error.error as ErrorMessageKeys] ??
          getMessageSubmitForm(error as any),
      );
    },
  });

  const mutationApplyToken = useMutation({
    mutationFn: ({ id, type }: { id: number; type: string }) =>
      ContractApi.applyToken(id, type),
    onSuccess: async () => {
      setOpen(false);
      refetch();
      toastMessage(NotificationType.success, 'Apply Token Success');
    },
    onError: async (error: { error: string }) => {
      toastMessage(
        NotificationType.error,
        ErrorMessage[error.error as ErrorMessageKeys] ??
          getMessageSubmitForm(error as any),
      );
    },
  });

  const handleToken = (role: string) => {
    role === ROLE_KEY.ADMIN
      ? mutationChangeToken.mutate({ id: Number(id), type: type })
      : mutationApplyToken.mutate({ id: Number(id), type: type });
    setOpen(false);
  };

  const columns: TableColumnsType = [
    {
      title: commonUI.approvalDate,
      dataIndex: 'time',
      width: '20%',
    },
    {
      title: commonUI.token,
      dataIndex: 'token',
      width: '40%',
    },
    {
      title: commonUI.tokenMaster,
      dataIndex: 'tokenMaster',
      width: '40%',
    },
  ];

  return (
    <div>
      <div className='h-full flex flex-col items-center'>
        <Form
          labelCol={{ xs: 20, sm: 8, md: 6, lg: 12, xl: 6 }}
          className='w-full h-full flex flex-col'
          labelAlign='left'
        >
          <div className='bg-white p-6 rounded-lg py-[16px] px-[24px] mb-[10px]'>
            <div className='w-full text-2xl font-semibold'>
              {commonUI.detailContract}
            </div>
          </div>
          <div className='h-full min-h-[81vh] flex flex-col justify-between bg-white p-6 rounded-lg'>
            <div>
              <div>
                <div className='flex justify-between'>
                  <Typography.Title level={4}>
                    <div className='border-b-2 border-current mb-4'>
                      {commonUI.infoContract}
                    </div>
                  </Typography.Title>
                  {role === ROLE_KEY.ADMIN &&
                    dataDetailContract?.status === STATUS_KEY.IN_ACTIVE && (
                      <div>
                        <Button
                          type='primary'
                          block={true}
                          onClick={() =>
                            navigate(
                              APP_ROUTER.CONTRACT.UPDATE.replace(
                                ':id',
                                String(id),
                              ),
                            )
                          }
                          className='w-full max-w-xs'
                          icon={<EditFilled />}
                        >
                          {commonUI.edit}
                        </Button>
                      </div>
                    )}
                </div>
                <Row gutter={[16, 12]}>
                  <Col xs={24} sm={12}>
                    <FieldReadOnly label={commonUI.companyCode}>
                      {dataDetailContract?.company?.code}
                    </FieldReadOnly>
                  </Col>
                  <Col xs={24} sm={12}>
                    <FieldReadOnly label={commonUI.companyName}>
                      {dataDetailContract?.company?.name}
                    </FieldReadOnly>
                  </Col>
                  <Col xs={24} sm={12}>
                    <FieldReadOnly label={commonUI.startDate}>
                      {dayjs(dataDetailContract?.startAt).format(
                        formatDate.FULL_DATE,
                      )}
                    </FieldReadOnly>
                  </Col>
                  <Col xs={24} sm={12}>
                    <FieldReadOnly label={commonUI.expiredDate}>
                      {dayjs(dataDetailContract?.expiredAt).format(
                        formatDate.FULL_DATE,
                      )}
                    </FieldReadOnly>
                  </Col>
                  <Col xs={24} sm={12}>
                    <FieldReadOnly label={commonUI.createDate}>
                      {dayjs(dataDetailContract?.createdAt).format(
                        formatDate.FULL_DATE,
                      )}
                    </FieldReadOnly>
                  </Col>
                  <Col xs={24} sm={12}>
                    <FieldReadOnly label={commonUI.status}>
                      <Tag
                        color={
                          statusColors[
                            dataDetailContract?.status as keyof typeof statusColors
                          ]
                        }
                      >
                        {
                          statusLabels[
                            dataDetailContract?.status as keyof typeof statusLabels
                          ]
                        }
                      </Tag>
                    </FieldReadOnly>
                  </Col>
                </Row>
              </div>
              <Divider />
              <Row gutter={[16, 12]} className='mb-4'>
                <Col xs={24} sm={12}>
                  <Typography.Title level={4} className='flex'>
                    <div className='border-b-2 border-current'>
                      {commonUI.token}
                    </div>
                  </Typography.Title>
                </Col>
                <Col xs={24} sm={12}>
                  {dataDetailContract &&
                    dataDetailContract?.status !== STATUS_KEY.IN_ACTIVE && (
                      <div>
                        <Button
                          className='w-[120px] bg-[#868990] hover:!bg-[#939599]'
                          type='primary'
                          onClick={() => setOpenHistories(true)}
                        >
                          {commonUI.history}
                        </Button>
                      </div>
                    )}
                </Col>
                <Col xs={24} sm={12}>
                  <Typography.Title level={5} className='flex'>
                    <div className='border-b border-current'>
                      {commonUI.tokenNormal}
                    </div>
                  </Typography.Title>
                  <FieldReadOnly label={commonUI.curToken}>
                    {dataDetailContract?.token}
                  </FieldReadOnly>
                </Col>
                <Col xs={24} sm={12}>
                  {role === ROLE_KEY.ADMIN &&
                    dataDetailContract &&
                    dataDetailContract.status === STATUS_KEY.ACTIVE && (
                      <Button
                        disabled={dataDetailContract?.tokenTmp}
                        className='w-[120px]'
                        type='primary'
                        onClick={() => {
                          setOpen(true);
                          setType('TOKEN');
                        }}
                      >
                        {commonUI.resetToken}
                      </Button>
                    )}
                  {role === ROLE_KEY.COMPANY &&
                    dataDetailContract &&
                    dataDetailContract.tokenTmp && (
                      <Button
                        className='w-[120px]'
                        type='primary'
                        onClick={() => {
                          setOpen(true);
                          setType('TOKEN');
                        }}
                      >
                        {commonUI.applyToken}
                      </Button>
                    )}
                </Col>
                {dataDetailContract && dataDetailContract.tokenTmp && (
                  <Col xs={24} sm={12}>
                    <FieldReadOnly label={commonUI.newToken}>
                      {dataDetailContract?.tokenTmp}
                    </FieldReadOnly>
                  </Col>
                )}
              </Row>
              <Row gutter={[16, 12]} className='mb-4'>
                <Col xs={24} sm={12}>
                  <Typography.Title level={5} className='flex'>
                    <div className='border-b border-current'>
                      {commonUI.tokenMaster}
                    </div>
                  </Typography.Title>
                  <FieldReadOnly label={commonUI.curToken}>
                    {dataDetailContract?.tokenMaster}
                  </FieldReadOnly>
                </Col>
                <Col xs={24} sm={12}>
                  {role === ROLE_KEY.ADMIN &&
                    dataDetailContract &&
                    dataDetailContract.status === STATUS_KEY.ACTIVE && (
                      <Button
                        disabled={dataDetailContract?.tokenMasterTmp}
                        className='w-[120px]'
                        type='primary'
                        onClick={() => {
                          setOpen(true);
                          setType('TOKEN_MASTER');
                        }}
                      >
                        {commonUI.resetToken}
                      </Button>
                    )}
                  {role === ROLE_KEY.COMPANY &&
                    dataDetailContract &&
                    dataDetailContract.tokenMasterTmp && (
                      <Button
                        className='w-[120px]'
                        type='primary'
                        onClick={() => {
                          setOpen(true);
                          setType('TOKEN_MASTER');
                        }}
                      >
                        {commonUI.applyToken}
                      </Button>
                    )}
                </Col>
                {dataDetailContract && dataDetailContract.tokenMasterTmp && (
                  <Col xs={24} sm={12}>
                    <FieldReadOnly label={commonUI.curToken}>
                      {dataDetailContract?.tokenMasterTmp}
                    </FieldReadOnly>
                  </Col>
                )}
              </Row>
            </div>
            <div className='flex gap-4 w-full mt-[30px] w-[80px]'>
              <Button
                onClick={() => navigate(APP_ROUTER.CONTRACT.LIST)}
                type='primary'
                ghost
                icon={<LeftCircleOutlined />}
              >
                {commonUI.back}
              </Button>
            </div>
          </div>
        </Form>
      </div>
      <Modal
        width={700}
        title={commonUI.tokenHistory}
        open={openHistories}
        onCancel={() => setOpenHistories(false)}
      >
        <div className='border border-solid border-inherit'>
          <Table
            rowKey='id'
            columns={columns}
            dataSource={convertHistories()}
            pagination={false}
            bordered
          />
        </div>
      </Modal>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title={commonUI.confirm}
        description={
          role === ROLE_KEY.ADMIN ? messageUI.changeToken : messageUI.applyToken
        }
      >
        <div className='flex justify-center gap-[10px] p-[20px] bg-[#E6FAFD]'>
          <Button
            onClick={() => setOpen(false)}
            className='w-full lg:w-[200px] bg-[#D6D9E0] hover:!bg-[#D9DDE6] text-gray-500 hover:!text-gray-500'
            type='primary'
          >
            {commonUI.no}
          </Button>
          <Button
            onClick={() => handleToken(role)}
            className='w-full lg:w-[200px]'
            type='primary'
            disabled={mutationApplyToken.isPending}
          >
            {commonUI.yes}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default DetailContract;
