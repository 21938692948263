import { TableColumnsType, Tag } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { CompanyApi } from '@/apis';
import { Link, useNavigate } from 'react-router-dom';
import {
  Button,
  Pagination,
  Table,
  DataEmpty,
  Input,
  Select,
} from '@/components/common';
import {
  companyStatusLabels,
  statusColors,
  statusLabels,
} from '@/const/option';
import { APP_ROUTER } from '@/routes/routes';
import { PlusOutlined, EyeFilled } from '@ant-design/icons';
import { convertObjectToArray } from '@/utils/helpers';
import { SearchOutlined } from '@ant-design/icons';
import { ENDPOINT } from '@/apis/endpoint';
import { commonUI } from '@/utils/text/UI';
import { messageUI } from '@/utils/text/message';

interface IParams {
  name: string;
  status: string | undefined;
  pageSize: number;
  page: number;
}

const ManagerCompany: React.FC = () => {
  const [search, setSearch] = useState('');
  const [params, setParams] = useState<IParams>({
    name: '',
    status: undefined,
    pageSize: 10,
    page: 1,
  });

  const navigate = useNavigate();
  const typingTimeoutRef = useRef<null | NodeJS.Timeout>(null);

  const { data, isLoading } = useQuery({
    queryKey: [ENDPOINT.COMPANY.GET_LIST, params],
    queryFn: () => CompanyApi.getListCompany(params),
  });

  const onChangePagination = (page: number, pageSize: number) => {
    setParams({ ...params, page, pageSize });
  };

  const columns: TableColumnsType = [
    {
      title: 'ID',
      dataIndex: 'code',
      width: '80px',
    },
    {
      title: commonUI.companyName,
      dataIndex: 'name',
      width: '110px',
    },
    {
      title: commonUI.email,
      dataIndex: 'email',
      width: '110px',
    },
    {
      title: commonUI.address,
      dataIndex: 'address',
      width: '80px',
    },
    {
      title: commonUI.status,
      dataIndex: 'status',
      width: '50px',
      render: (status: string) => {
        return (
          <Tag color={statusColors[status as keyof typeof statusColors]}>
            {statusLabels[status as keyof typeof statusLabels]}
          </Tag>
        );
      },
    },
    {
      render: (record: { id: number }) => {
        return (
          <div>
            <Link
              to={APP_ROUTER.COMPANY.DETAIL.replace(':id', String(record.id))}
            >
              <Button type='primary' icon={<EyeFilled />}>
                {commonUI.detail}
              </Button>
            </Link>
          </div>
        );
      },
      fixed: 'right',
      align: 'center',
      width: '50px',
    },
  ];

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      setParams((prev) => ({
        ...prev,
        name: search,
      }));
    }, 500);
  }, [search]);

  return (
    <div>
      <div className='bg-white px-[24px] py-[16px] rounded-lg mb-[10px]'>
        <div className='flex flex-col justify-between gap-4 md:flex-row'>
          <div className='w-full text-2xl font-semibold truncate'>
            {commonUI.listCompany}
          </div>
          <div className='flex flex-col md:flex-row gap-[10px] justify-end w-full'>
            <Input
              allowClear
              className='w-full md:w-[260px]'
              placeholder={commonUI.companyName}
              prefix={<SearchOutlined />}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Select
              allowClear
              className='w-full md:w-[200px]'
              placeholder={commonUI.status}
              onChange={(e) =>
                setParams((prev) => ({
                  ...prev,
                  status: e,
                }))
              }
              options={convertObjectToArray(companyStatusLabels, 'string')}
            />
            <Button
              type='primary'
              icon={<PlusOutlined />}
              className='w-full md:w-[120px]'
              onClick={() => navigate(APP_ROUTER.COMPANY.CREATE)}
            >
              {commonUI.register}
            </Button>
          </div>
        </div>
      </div>
      <div className='h-full min-h-[81vh] bg-white rounded-lg'>
        <div className='flex flex-col'>
          <div>
            <Table
              loading={isLoading}
              scroll={{ x: 1520 }}
              rowKey='id'
              columns={columns}
              dataSource={data?.data}
              pagination={false}
            />
          </div>
          {data && data.pagination && data.pagination.total > 10 && (
            <Pagination
              className='pagination-table !pr-[5px]'
              total={data?.pagination.total}
              pageSize={params.pageSize}
              current={params.page}
              onChange={onChangePagination}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ManagerCompany;
